// Constant Variables
// ================================================================================

// Layout
$grid-margin:           calc(0.25em + 0.25vw);

// Breakpoints
$mobile-size:           1024px;    

// Interface
$card-padding:          calc(0.5em + 0.5vw);
$transition-time:       0.25s;
$navigation-height:     60px;

// Typography
$h1-font-size:          calc(1.5em + 1vw);
$h2-font-size:          calc(1em + 0.25vw);
$h3-font-size:          calc(0.75em + 0.25vw);
$p-font-size:           calc(0.75em + 0.25vw);
$subp-font-size:        calc(0.65em + 0.25vw);

$paragraph-margin:      1.5rem;


// Dynamic Variables
// ================================================================================

:root {
    // Theme Colors (Hue, Saturation)
    --color-gray:      0, 0%,;
    --color-red:        357, 66%;
    --color-orange:     23, 80%;
    --color-yellow:     59, 68%;
    --color-green:      141, 55%;
    --color-teal:       173, 66%;
    --color-blue:       202, 68%;
    --color-navy:       223, 74%;
    --color-purple:     269, 46%;

    // Interface Colors (Hue, Saturation)
    --color-neutral:    223, 12%;
    --color-affirm:     119, 53%;
    --color-negate:     353, 53%;

    // Interface
    --card-border:      solid 1px hsl( var(--color-blue), 50%, 0.5);
    --card-background:  hsl( var(--color-blue), 50%, 0.15);

    --block-radius:     5px;
    --ui-radius:        5px;
    
    // Typography
    --primary-font:     -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --secondary-font:   'Slider', var(--primary-font);
}

// Theme Colors
// $color-white:       #fff;
// --color-white:      #{$color-white};

// $color-red:        rgb(144, 29, 35);
// --color-red:        #{$color-red};
// --color-red-l:      #{lighten( $color-red, 12%)};
// --color-red-d:      #{darken( $color-red, 12%)};

// $color-orange:     rgb(231, 109, 35);
// --color-orange:     #{$color-orange};
// --color-orange-l:   #{lighten( $color-orange, 12%)};
// --color-orange-d:   #{darken( $color-orange, 12%)};

// $color-yellow:     rgb(212, 208, 41);
// --color-yellow:     #{$color-yellow};
// --color-yellow-l:   #{lighten( $color-yellow, 12%)};
// --color-yellow-d:   #{darken( $color-yellow, 12%)};

// $color-green:      rgb(55, 189, 101);
// --color-green:      #{$color-green};
// --color-green-l:    #{lighten( $color-green, 12%)};
// --color-green-d:    #{darken( $color-green, 12%)};

// $color-teal:       rgb(36, 176, 159);
// --color-teal:       #{$color-teal};
// --color-teal-l:     #{lighten( $color-teal, 12%)};
// --color-teal-d:     #{darken( $color-teal, 12%)};

// $color-blue:       rgb(40, 148, 210);
// --color-blue:       #{$color-blue};
// --color-blue-l:     #{lighten( $color-blue, 12%)};
// --color-blue-d:     #{darken( $color-blue, 12%)};

// $color-navy:       rgb(19, 49, 128);
// --color-navy:       #{$color-navy};
// --color-navy-l:     #{lighten( $color-navy, 12%)};
// --color-navy-d:     #{darken( $color-navy, 12%)};

// $color-purple:     rgb(112, 61, 165);
// --color-purple:     #{$color-purple};
// --color-purple-l:   #{lighten( $color-purple, 12%)};
// --color-purple-d:   #{darken( $color-purple, 12%)};

// $color-black:      rgb(11, 11, 11);
// --color-black:      #{$color-black};
// --color-black-l:    #{lighten( $color-black, 12%)};
// --color-black-d:    #{darken( $color-black, 12%)};