* {
	box-sizing: border-box;
}
body {
	background: hsl( var(--color-neutral), 12%, 1);
	// background: color-neutral(-20);
	// background: #990000;
	// background-color: #272741;
	background-image: url('/images/star-background-2.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	// padding: calc( #{$grid-margin} * 3 );
	margin: 0 auto;
	position: relative;
}
.module {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: calc(100% - 4em);
}
.card {
	background: var(--card-background);
	border: var(--card-border);
	border-radius: var(--block-radius);
	padding: $card-padding;
	&.card--red {
		border: solid 1px hsl( var(--color-red), 50%, 0.5);
    	background: hsl( var(--color-red), 50%, 0.15);
	}
	&.card--orange {
		border: solid 1px hsl( var(--color-orange), 50%, 0.5);
    	background: hsl( var(--color-orange), 50%, 0.15);
	}
	&.card--yellow {
		border: solid 1px hsl( var(--color-yellow), 50%, 0.5);
    	background: hsl( var(--color-yellow), 50%, 0.15);
	}
	&.card--green {
		border: solid 1px hsl( var(--color-green), 50%, 0.5);
    	background: hsl( var(--color-green), 50%, 0.15);
	}
	&.card--teal {
		border: solid 1px hsl( var(--color-teal), 50%, 0.5);
    	background: hsl( var(--color-teal), 50%, 0.15);
	}
	&.card--blue {
		border: solid 1px hsl( var(--color-blue), 50%, 0.5);
    	background: hsl( var(--color-blue), 50%, 0.15);
	}
	&.card--navy {
		border: solid 1px hsl( var(--color-navy), 50%, 0.5);
    	background: hsl( var(--color-navy), 50%, 0.15);
	}
	&.card--purple {
		border: solid 1px hsl( var(--color-purple), 50%, 0.5);
    	background: hsl( var(--color-purple), 50%, 0.15);
	}
}

.modal-container {
	left: 100vw;
    display: flex; 
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
	background: linear-gradient( hsl( var(--color-blue), 12%, 1), hsl( var(--color-blue), 4%, 1));
	padding: $paragraph-margin;
	transition: transform 0.2s ease-in-out;
	&.modal--show {
		transform: translateX(-100vw);
	}
	// @media (min-width: $mobile-size) {

	// }
}
.modal {
	width: 100%;
	max-height: 100%;
	max-width: 760px;
	overflow-x: hidden;
	overflow-y: scroll;
	&.modal--show {
		display: block;
	}
	@media (min-width: $mobile-size) {
		position: relative;
	}
	@media (max-width: $mobile-size) {
		
		padding: 0;
		border: none;
		background: transparent;
	}
}
.modal__close-btn {
	position: absolute;
	padding: calc( #{$card-padding} / 2);
	top: 0;
	right: 0;
	line-height: 0;
	svg {
		fill: hsl( var(--color-neutral), 100%, 100% );
		height: 32px;
		width: 32px;
	}
	&:hover {
		background: hsl( var(--color-neutral), 50%, 0.85 );
		border: none;
	}
}
