// Button Base
@mixin button {
    font-family: var(--secondary-font);
    font-weight: 700;
    font-size: $p-font-size;
    border-radius: var(--ui-radius);
    color: #fff;
    background: hsl( var(--color-neutral), 50%, 1);
    cursor: pointer;
    line-height: 1;
    padding: 0.5em 1em;
    text-transform: uppercase;
    @include font-smoothing;
    &[disabled] {
        opacity: 0.25;
    }
    &:hover { background: hsl( var(--color-neutral), 56%, 1); }
}

// Button Sizes
@mixin button-lg {
    @include button;
    padding: 0.75em 1.25em;
}
@mixin button-md {
    @include button;
    font-size: calc( #{$p-font-size} * 0.9);
    font-family: var(--primary-font);
    text-transform: none;
}
@mixin button-sm {
    @include button;
    font-size: calc( #{$p-font-size} * 0.8);
    font-family: var(--primary-font);
    text-transform: none;
}
.button-lg {@include button-lg;}
.button-md {@include button-md;}
.button-sm {@include button-sm;}


// Button Standard Colors
@mixin primary-button {
    background: hsl( var(--color-orange), 50%, 1);
    &:hover { background: hsl( var(--color-orange), 56%, 1); }
}
@mixin neutral-dark-button {
    background: hsl( var(--color-neutral), 12%, 1);
    &:hover { background: hsl( var(--color-neutral), 18%, 1); }
}
@mixin neutral-light-button {
    background: hsl( var(--color-neutral), 70%, 1);
    color: hsl( var(--color-neutral), 16%, 1);
    &:hover { background: hsl( var(--color-neutral), 64%, 1); }
}
@mixin affirm-button {
    background: hsl( var(--color-affirm), 50%, 1);
    &:hover { background: hsl( var(--color-affirm), 56%, 1); }
}
@mixin negate-button {
    background: hsl( var(--color-negate), 50%, 1);
    &:hover { background: hsl( var(--color-neutral), 56%, 1); }
}
.primary-button { @include primary-button; }
.neutral-dark-button { @include neutral-dark-button; }
.neutral-light-button { @include neutral-light-button; }
.affirm-button { @include affirm-button; }
.negate-button { @include negate-button; }

// Button Theme Colors
@mixin red-button {
    background: hsl( var(--color-red), 50%, 1);
    &:hover { background: hsl( var(--color-red), 56%, 1); }
}
@mixin orange-button {
    background: hsl( var(--color-orange), 50%, 1);
    &:hover { background: hsl( var(--color-orange), 56%, 1); }
}
@mixin yellow-button {
    background: hsl( var(--color-yellow), 40%, 1);
    &:hover { background: hsl( var(--color-yellow), 46%, 1); }
}
@mixin green-button {
    background: hsl( var(--color-green), 40%, 1);
    &:hover { background: hsl( var(--color-green), 46%, 1); }
}
@mixin teal-button {
    background: hsl( var(--color-teal), 40%, 1);
    &:hover { background: hsl( var(--color-teal), 46%, 1); }
}
@mixin blue-button {
    background: hsl( var(--color-blue), 50%, 1);
    &:hover { background: hsl( var(--color-blue), 56%, 1); }
}
@mixin navy-button {
    background: hsl( var(--color-navy), 50%, 1);
    &:hover { background: hsl( var(--color-navy), 56%, 1); }
}
@mixin purple-button {
    background: hsl( var(--color-purple), 50%, 1);
    &:hover { background: hsl( var(--color-purple), 56%, 1); }
}
.button--red{ @include red-button; }
.button--orange{ @include orange-button; }
.button--yellow{ @include yellow-button; }
.button--green{ @include green-button; }
.button--teal{ @include teal-button; }
.button--blue{ @include blue-button; }
.button--navy{ @include navy-button; }
.button--purple{ @include purple-button; }