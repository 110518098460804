$create-thumb-size: calc(48px + 1vw);

.players__create-player {

    .form__field {
        .flex-row {
            flex-flow: row wrap;
            justify-content: flex-start;
        }
    }
    
    input[type="radio"]{
        position: absolute;
        left: -9999px;

        &:checked ~ label {
            // opacity: 1;
            &:after {
                content: "";
                position: absolute;
                left: -2px;
                top: -2px;
                width: 100%;
                height: 100%;
                border: solid 2px hsl( var(--color-orange), 56%, 1) !important;
                border-radius: calc( var(--ui-radius) + 2px);
            }
        }

        &:disabled ~ label {
            opacity: 0.2;
            position: relative;
            // filter: grayscale(100%);
            &:hover:before {
                content: none;
            }
            &:after {
                content: "";
                background-image: url('images/cancel-white.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 75%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    label {
        position: relative;
        display: block;
        color: transparent;
        border-radius: var(--ui-radius);
        margin: 0;
        cursor: pointer;
        // box-shadow: 0 0 0 1px rgba(#000, 0.75);
        // box-shadow: 0 1px 1px 0 rgba(#000, 0.75);
        // border: solid 1px rgba(#000, 0.5);
        // opacity: 0.8;
        &:hover:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0 0 0px 2px inset hsl( var(--color-neutral), 100%, 0.3); 
            border-radius: var(--ui-radius);
        }
    }

    .grid-row {
        display: flex;
        flex-flow: row wrap;
        margin: calc( #{$grid-margin} * -1 );
        & > * {
            flex-basis: calc( 16.667% - #{$grid-margin} );
            margin: calc( #{$grid-margin} * 0.5);
            line-height: 0;
            @media (min-width: 420px) {
                flex-basis: calc( 12.5% - ( #{$grid-margin} * 2) );
            }
        }
        @media (min-width: 600px) {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(72px, 1fr));
            grid-gap: $grid-margin;
        }
    }
}

.create-player__race {
    img {
        width: 100%;
        border-radius: var(--ui-radius);
    }
}

.create-player__color {
    span {
        position: absolute;
        left: -9999px;
    }
    label {
        background-image: url('images/textures/real-carbon-fibre.png');
        background-size: 30px;
        background-blend-mode: multiply;
        // border: var(--card-border);
        // background-color: #444;
        // border: solid 2px #fff;
        // padding-top: calc( 100% - ( var(--grid-margin) * 2) );
        &[for="create-player-color-black"] {
            background-color: hsl( var(--color-gray), 20%, 1); 
            path { fill: hsl( var(--color-neutral), 80%, 1);  }
        }
        &[for="create-player-color-blue"] { 
            background-color: hsl( var(--color-blue), 50%, 1);
        }
        &[for="create-player-color-green"] {
            background-color: hsl( var(--color-green), 50%, 1);
        }
        &[for="create-player-color-red"] {
            background-color: hsl( var(--color-red), 50%, 1);
        }
        &[for="create-player-color-purple"] {
            background-color: hsl( var(--color-purple), 50%, 1);
        }
        &[for="create-player-color-yellow"] {
            background-color: hsl( var(--color-yellow), 50%, 1);
        }
    }
    svg {
        width: 100%;
        max-width: 100%;
        opacity: 0.5;
        // background-blend-mode: overlay;
        transform: scale(0.85);
        path {
            fill: hsl( var(--color-neutral), 16%, 1); 
        }
    }
}