.strategies {
    text-align: center;
    flex-flow: row wrap;
    .card {
        flex-basis: calc( 50% - (#{$grid-margin} * 2) );
        margin-bottom: $grid-margin;
    }
    h2 {
        font-size: $subp-font-size;
        margin-bottom: 0.5em;
    }
}

.strategy__players {
    // display: flex;
    // flex-flow: row wrap;
    // justify-content: center;
    button {
        margin: 0.25em;
    }
    // button {
    //     margin: 0.25em;
    //     display: block;
    //     padding: 0.5em 1em;
    //     border-radius: var(--ui-radius);
    //     background: hsl( var(--color-neutral), 50%, 0.8);
    //     color: hsl( var(--color-neutral), 100%, 1);
    //     font-family: var(--primary-font);
    //     &:hover {
    //         cursor: pointer;
    //     }
    // }
}