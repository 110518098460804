@font-face {
    font-family: 'Slider';
    src: url('fonts/Slider.woff2') format('woff2'),
        url('fonts/Slider.woff') format('woff'),
        url('fonts/Slider.ttf') format('truetype'),
        url('fonts/Slider.svg#Slider') format('svg');
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6, p, label, span {
    @include font-smoothing;
    color: hsl( var(--color-neutral), 100%, 1);
}

h1, h2, h3, h4, h5, h6, p, span {
    margin: 0 0 $paragraph-margin;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--secondary-font);
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

h1 {
    font-size: $h1-font-size;
}
h2 {
    font-size: $h2-font-size;
}
h3 {
    font-size: $h3-font-size;
}
p, label {
    font-family: var(--primary-font);
    font-size: $p-font-size;
}
label {
    margin-bottom: calc( #{$paragraph-margin} * 0.25);
}