nav {
    @media (min-width: 768px ) {
        justify-self: center;
    }
    ul {
        // display: flex;
        // flex-flow: row nowrap;
        // justify-content: center;
        padding: 0;
        margin: 0 auto;
        list-style: none;
        // width: calc(120px * 4);
        width: 100%;
        display: grid;
        grid-template-rows: $navigation-height;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        
    }
    button, a {
        display: block;
        color: #fff;
        background: blue;
        text-decoration: none;
        font-size: 0.8em;
        width: 100%;
        height: $navigation-height;
        position: relative;
        text-align: center;
        // background: linear-gradient( hsl( var(--color-neutral), 52%, 0.2), hsl( var(--color-neutral), 52%, 0.50) );
        background: linear-gradient( hsl( var(--color-blue), 52%, 0.2), hsl( var(--color-blue), 52%, 0.45) );
        &.active {
            background: linear-gradient( hsl( var(--color-orange), 52%, 0.25), hsl( var(--color-orange), 52%, 0.90) );
        }
        svg {
            fill: #fff;
            width: 24px;
        }
        p {
            margin: 0;
        }
        @media (min-width: 768px ) {
            width: 100px;
        }
    }
    .nav__label {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    li {
        &:first-child button {
            border-radius: var(--ui-radius) 0 0 0;
        }
        &:last-child button {
            border-radius: 0 var(--ui-radius) 0 0;
        }
    }
}