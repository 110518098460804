@mixin card-style {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(#000, 0.2);
}
@mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@mixin transition($transition-property, $transition-time: 0.25s) {
    transition: $transition-property ease-in-out $transition-time;
}

@mixin spaced-letters {
    text-transform: uppercase;
    letter-spacing: 0.25em;
}