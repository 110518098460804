.form__field {
    margin: 0 0 $paragraph-margin;
}

label {
    display: block;
}

select,
textarea,
input[type] {
    border-radius: var(--ui-radius);
    border: none;
    outline: none;
    padding: calc( #{$paragraph-margin} * 0.5);
    font-family: var(--primary-font);
    font-weight: 700;
}